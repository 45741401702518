import styled, { keyframes, css } from 'styled-components'

export const Backdrop = styled.div<{ transparent: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.transparent ? 'transparent' : `var(--modal-backdrop-background-color)`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
`

const shakeWarning = keyframes`
  0% {
    margin-left: -10px;
  }
  12% {
    margin-left: 20px;
  }
  25% {
    margin-left: -40px;
  }
  50% {
    margin-left: 40px;
  }
  62%{
    margin-left: -40px;
  }
  75% {
    margin-left: -20px;
  }
  87%{
    margin-left: 20px;
  }
  100% {
    margin-left: -10px;
  }
`

export const MainContainer = styled.div<{ shakeWarning: boolean }>`
  position: relative;
  background: var(--modal-background-color);
  border-radius: 8px;
  max-width: 95vw;
  width: 640px;
  max-height: 95vh;
  overflow: hidden;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.22);
  color: var(--typography400);
  font-size: 1rem;

  ${(props) =>
    props.shakeWarning &&
    css`
      animation: ${shakeWarning} 0.6s cubic-bezier(0, 0.28, 0.99, 0.72);
    `}
`

export const BottomFade = styled.div`
  position: absolute;
  bottom: 0;
  height: 80px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: var(--modal-bottom-fade-background);
`

export const ContentWrapper = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 80px;
  max-height: 95vh;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`

export const Title = styled.h3`
  color: var(--typography500);
  font-size: 2rem;
  line-height: 2.5rem;
`

export const Body = styled.div`
  padding: 0;
`

export const Header = styled.div`
  padding: 0 0 1.5rem;
`

export const PreHeader = styled.div`
  padding: 0 0 1.5rem;
`

export const Footer = styled.div`
  padding: 0.75rem 0 0;
`

export const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;

  button {
    flex-grow: 1;
  }
`

export const ButtonSpacer = styled.div`
  width: 1rem;
`
