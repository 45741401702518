import styled from 'styled-components';

import { IInterUIIconProps } from '../../interfaces/inter-ui-icon-props';

const setSize = (props: IInterUIIconProps) => {
  if (!props.size) {
    return '24px';
  } else {
    switch (props.size.toLowerCase()) {
      case 'sm':
        return '16px';
      case 'md':
        return '24px';
      case 'ld':
        return '32px';
      case 'xl':
        return '72px';
      default:
        return props.size;
    }
  }
};

/**
 * Span principal.
 */
export const Container = styled.span<IInterUIIconProps>`
  height: ${setSize};
  font-size: ${setSize};
  line-height: ${setSize};

  color: ${(props) => props.color || props.theme.colors.grayscale.A400};
`;
