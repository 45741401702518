import styled from 'styled-components';

import { InterUISnackbarType } from './../../interfaces/inter-ui-snackbar-props';

/**
 * Div principal do snackbar
 */
export const Container = styled.div<{ toggle: boolean }>`
  position: fixed;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;

  ${(props) => props.toggle && 'opacity: 1; transition: opacity 0.3s ease-in;'}

  ${(props) =>
    !props.toggle && 'opacity: 0; transition: opacity 0.3s ease-out;'}
`;
/**
 *
 * Div com o corpo do snackbar
 */
export const Snackbar = styled.div<{ default?: boolean }>`
  display: flex;

  ${(props) => !props.default && `width: 100%;`}

  max-width: 312px;
  min-width: 117px;
  background-color: ${(props) => props.theme.colors.grayscale.A500};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.base.theme};
`;

/**
 * Div para alinhar o conteúdo do snackbar
 */
export const Content = styled.div<{ type: InterUISnackbarType }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;

  ${(props) => props.type === 'link' && 'justify-content: space-between;'}

  ${(props) =>
    props.type === 'default' &&
    `justify-content: center;
      span {
        text-align: center;
      }`}

  ${(props) =>
    (props.type === 'full' || props.type === 'link') && `width: 100%;`}

  ${(props) => props.type === 'full' && 'flex-direction: column;'}
`;

/**
 * Div para descrições longas
 */
export const FullText = styled.div`
  width: 100%;
  text-align: left;
  padding-bottom: 8px;
`;

/**
 * Definições relacionadas ao texto
 */
export const TextContent = styled.span`
  font-size: 0.75rem;
  line-height: 0.938rem;
  font-weight: 700;
`;

/**
 * Div para o link que ocupa uma linha
 */
export const FullLink = styled.div`
  width: 100%;
  padding-top: 8px;
  text-align: right;
`;

/**
 * Definições relacionadas ao link
 */
export const LinkContent = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #ff7a00;
  color: ${(props) => props.theme.colors.base.white};
  font-size: 0.75rem;
  line-height: 0.938rem;
`;
