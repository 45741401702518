import styled, { css } from 'styled-components';

import { IInterUITagProps } from '../../interfaces/inter-ui-tag-props';

/**
 * Tag default.
 */
export const Default = styled.span<IInterUITagProps>`
  display: inline-block;
  ${(props) => props.margin && `margin: ${props.margin};`}
  padding: 2px 4px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.grayscale.A500};
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: bold;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.base.theme};
`;

/**
 * Tag com background na cor principal do tema.
 */
export const Primary = styled(Default)`
  background-color: ${(props) => props.theme.colors.primary.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para alerta.
 */
export const Alert = styled(Default)`
  background-color: ${(props) => props.theme.colors.alert.A500};
  color: ${(props) => props.theme.colors.base.black};
`;

/**
 * Tag para sucesso.
 */
export const Success = styled(Default)`
  background-color: ${(props) => props.theme.colors.success.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para erro.
 */
export const Error = styled(Default)`
  background-color: ${(props) => props.theme.colors.error.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor grape.
 */
export const Grape = styled(Default)`
  background-color: ${(props) => props.theme.colors.grape.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor mirtilo.
 */
export const Mirtilo = styled(Default)`
  background-color: ${(props) => props.theme.colors.mirtilo.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor raspberry.
 */
export const Raspberry = styled(Default)`
  background-color: ${(props) => props.theme.colors.raspberry.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor avocado.
 */
export const Avocado = styled(Default)`
  background-color: ${(props) => props.theme.colors.avocado.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor lime.
 */
export const Lime = styled(Default)`
  background-color: ${(props) => props.theme.colors.lime.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor pitaya.
 */
export const Pitaya = styled(Default)`
  background-color: ${(props) => props.theme.colors.pitaya.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor strawberry.
 */
export const Strawberry = styled(Default)`
  background-color: ${(props) => props.theme.colors.strawberry.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor toranja.
 */
export const Toranja = styled(Default)`
  background-color: ${(props) => props.theme.colors.toranja.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor coconut.
 */
export const Coconut = styled(Default)`
  background-color: ${(props) => props.theme.colors.coconut.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag para cor pear.
 */
export const Pear = styled(Default)`
  background-color: ${(props) => props.theme.colors.pear.A500};
  color: ${(props) => props.theme.colors.base.white};
`;

/**
 * Tag com dimensões maiores.
 */
export const Big = styled.span<IInterUITagProps>`
  display: inline-block;
  ${(props) => props.margin && `margin: ${props.margin};`}

  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.grayscale.A100};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  font-weight: bold;
  color: ${(props) => props.color};
`;

/**
 * Layout default da tag action.
 */
const Action = css<IInterUITagProps>`
  ${(props) => props.margin && `margin: ${props.margin};`}

  padding: 8px 16px;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => `1px solid ${props.theme.colors.grayscale.A200}`};
  border-radius: 20px;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${(props) => props.color};
`;

/**
 * Container para action radio/checkbox.
 */
export const ActionCheck = styled.div<IInterUITagProps>`
  display: inline-block;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.margin && `margin: ${props.margin};`}
`;

/**
 * Label para action radio/checkbox.
 */
export const InputLabel = styled.label<IInterUITagProps>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  display: inline-block;

  ${Action}

  transition: all 0.3s;
`;

/**
 * Input hidden para action radio/checkbox.
 */
export const InputHidden = styled.input`
  display: none;

  &:checked {
    + ${InputLabel} {
      background-color: ${(props) => props.theme.colors.grayscale.A500};
      border-color: ${(props) => props.theme.colors.grayscale.A500};
      color: ${(props) => props.theme.colors.base.theme};
    }
  }
`;

/**
 * Action button.
 */
export const ActionButton = styled.button<IInterUITagProps>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};

  ${Action}

  .inter-ic-arrow-chevron-down {
    margin-left: 4px;
  }
`;

/**
 * Counter utilizado dentro da tag action button.
 */
export const Counter = styled.span<IInterUITagProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin-left: 8px;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.grayscale.A500};
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.base.white};
  border-radius: 50%;
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: bold;
`;
