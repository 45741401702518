import React from 'react'

import { Button } from '../../Button'
import * as S from '../BaseModal/styles'
import { BaseModal, BaseModalProps } from '../BaseModal'

export type ConfirmationModalProps = Omit<BaseModalProps, 'footer'> & {
  secondaryButtonLabel?: string
  secondaryButtonDisabled?: boolean
  secondaryActionProcessing?: boolean
  onPrimary: () => void
  onSecondary: () => void
  primaryButtonLabel?: string
  primaryButtonDisabled?: boolean
  primaryActionProcessing?: boolean
  processing?: boolean
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/modals-confirmation-modal-since-v2-0-20--confirmation)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/FS12HnaqvY6B4K9mkTCSrr/DS-%2F-Components-%2F-Desktop?node-id=2539-7198&t=RabwOuj1svlNaWmz-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState, useCallback } from 'react'
 *
 * import { ConfirmationModal } from '@interco/inter-ui/components/Modal/ConfirmationModal'
 * import { Button } from '@interco/inter-ui/components/Button'
 *
 * export const MyPage = () => {
 *   const [visible, setVisible] = useState<boolean>(false)
 *
 *   const closeModal = useCallback(() => {
 *     setVisible(false)
 *   }, [setVisible])
 *
 *   const onModalCancel = useCallback(() => {
 *     setVisible(false)
 *     // Other actions
 *   }, [closeModal])
 *
 *   const onModalConfirm = useCallback(() => {
 *     setVisible(false)
 *     // Other actions
 *   }, [closeModal])
 *
 *   return (
 *     <>
 *       <Button onClick={() => setVisible(true)}>Open Modal</Button>
 *
 *       <ConfirmationModal
 *         visible={visible}
 *         onClose={closeModal}
 *         title="Isto é uma confirmação"
 *         onPrimary={onModalConfirm}
 *         onSecondary={onModalCancel}
 *       >
 *         Modal
 *       </ConfirmationModal>
 *     </>
 *   )
 * }
 * ```
 */
export const ConfirmationModal = ({
  secondaryButtonLabel = 'Cancelar',
  secondaryButtonDisabled = false,
  secondaryActionProcessing = false,
  onPrimary,
  onSecondary,
  primaryButtonLabel = 'Confirmar',
  primaryButtonDisabled = false,
  primaryActionProcessing = false,
  processing = false,
  children,
  ...baseModalProps
}: ConfirmationModalProps) => {
  const footer = (
    <S.ButtonsFooter>
      <Button
        name="secondary-button"
        variant="secondary"
        isLoading={secondaryActionProcessing || processing}
        disabled={
          secondaryButtonDisabled ||
          secondaryActionProcessing ||
          primaryActionProcessing ||
          processing
        }
        onClick={onSecondary}
      >
        {secondaryButtonLabel}
      </Button>
      <S.ButtonSpacer />
      <Button
        name="primary-button"
        variant="primary"
        isLoading={primaryActionProcessing || processing}
        disabled={
          primaryButtonDisabled ||
          primaryActionProcessing ||
          secondaryActionProcessing ||
          processing
        }
        onClick={onPrimary}
      >
        {primaryButtonLabel}
      </Button>
    </S.ButtonsFooter>
  )

  return (
    <BaseModal footer={footer} {...baseModalProps}>
      {children}
    </BaseModal>
  )
}
