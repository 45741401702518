import React from 'react';
import { useTheme } from 'styled-components';
import { InterUIIcon } from '../../core';
import { IInterUIBabiProps } from '../../interfaces/inter-ui-babi-props';
import {
  BabiContainer,
  Text,
  WrapperIcon,
  WrapperImg,
  WrapperText
} from './InterUIBabi.styles';

export const BABI_IMG_CDN =
  'https://static.bancointer.com.br/inter-ui/assets/babi-avatar.png';

export const InterUIBabi: React.FC<IInterUIBabiProps> = ({
  title,
  subTitle,
  image,
  onClick
}) => {
  const theme = useTheme();

  return (
    <BabiContainer onClick={onClick} data-testid='babi'>
      <WrapperImg>
        <img src={image ?? BABI_IMG_CDN} alt='imagem babi' />
      </WrapperImg>

      <WrapperText>
        <Text
          color={theme.colors.grayscale.A500}
          fontWeight='700'
          fontSize='0.875rem'
        >
          {title}
        </Text>
        <Text
          color={theme.colors.grayscale.A400}
          fontWeight='400'
          fontSize='0.75'
        >
          {subTitle}
        </Text>
      </WrapperText>

      <WrapperIcon>
        <InterUIIcon
          name='arrow-chevron-right'
          size='md'
          color={theme.colors.primary.A500}
        />
      </WrapperIcon>
    </BabiContainer>
  );
};

InterUIBabi.defaultProps = {
  title: 'Oi, posso ajudar?',
  subTitle: 'A Babi vai te ajudar com todo o conteúdo da nossa central.'
};
