import styled, { css } from 'styled-components';

import { IInterUIInputGroupProps } from '../../interfaces/inter-ui-input-group-props';

const fontColorLabel = (props: any) => {
  const colors = props.theme.colors;

  if (props.disabled) {
    return colors.grayscale.A300;
  } else if (props.invalid) {
    return colors.error.A500;
  } else if (props.valid) {
    return colors.success.A500;
  } else {
    return colors.grayscale.A400;
  }
};

const backgroundColorInput = (props: any) => {
  const colors = props.theme.colors;

  if (props.invalid) {
    return colors.error.A100;
  } else if (props.valid) {
    return colors.success.A100;
  } else {
    return colors.grayscale.A100;
  }
};

const fontColorInput = (props: any) => {
  const colors = props.theme.colors;
  return colors.grayscale.A500;
};

const fontColorLabelPricing = (props: any) => {
  const colors = props.theme.colors;
  if (props.disabled) {
    return colors.grayscale.A300;
  } else {
    return colors.grayscale.A500;
  }
};

const fontColorInputPricing = (props: any) => {
  const colors = props.theme.colors;
  if (props.disabled) {
    return colors.grayscale.A200;
  } else if (props.invalid) {
    return colors.error.A100;
  } else {
    return colors.primary.A500;
  }
};

/**
 * Label a ser exibida acima do input/textarea.
 */
const Label = css`
  label {
    display: block;
    margin: 0 0 4px 2px;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: ${fontColorLabel};
  }
`;

/**
 * Input/textarea.
 */
const Input = css`
  input,
  textarea {
    width: calc(100% - 32px);
    padding: 14px 16px;
    background-color: ${backgroundColorInput};
    border-radius: 8px;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 700;
    color: ${fontColorInput};

    &::placeholder {
      color: ${(props) => props.theme.colors.grayscale.A300};
    }

    &:read-only {
      width: calc(100% - 56px);
      padding-right: 40px;
      color: ${(props) => props.theme.colors.grayscale.A400};

      &::placeholder {
        color: ${(props) => props.theme.colors.grayscale.A400};
      }
    }

    &:disabled {
      color: ${(props) => props.theme.colors.grayscale.A200};

      &::placeholder {
        color: ${(props) => props.theme.colors.grayscale.A200};
      }
    }
  }
`;

/**
 * Label a ser exibida acima do input pricing
 */
const LabelPricing = css`
  label {
    display: block;
    font-weight: 600;
    font-family: 'Sora';
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${fontColorLabelPricing};
  }
`;

/**
 * Prefixo a ser exibido ao lado do input pricing
 */
export const PrefixPricing = styled.span<{
  disabled?: boolean;
  invalid?: boolean;
}>`
  padding: 16px 0px 4px 0px;
  font-family: 'Sora';
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: ${fontColorInputPricing};
  border-bottom: 1px solid ${(props) => props.theme.colors.grayscale.A300};
`;

/**
 * Input da variação pricing
 */
const InputPricing = css`
  input {
    max-width: calc(100% - 32px);
    font-family: 'Sora';
    ${(props: IInterUIInputGroupProps) =>
      props.width && `width: ${props.width};`};
    border-bottom: 1px solid ${(props) => props.theme.colors.grayscale.A300};
    padding: 16px 0px 4px 0px;
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 600;
    background-color: ${(props) => props.theme.colors.base.theme};
    color: ${fontColorInputPricing};

    &::placeholder {
      color: ${fontColorInputPricing};
    }

    &:read-only {
      ${(props: IInterUIInputGroupProps) =>
        props.width && `width: ${props.width};`};
    }

    &:disabled {
      color: ${(props) => props.theme.colors.grayscale.A200};

      &::placeholder {
        color: ${(props) => props.theme.colors.grayscale.A200};
      }
    }
  }
`;

/**
 * Div principal.
 */
export const Container = styled.div`
  position: relative;

  ${(props: IInterUIInputGroupProps) =>
    props.margin && `margin: ${props.margin};`}

  ${(props: IInterUIInputGroupProps) =>
    (props.variant === 'pricing' && LabelPricing) || Label}

  ${(props: IInterUIInputGroupProps) =>
    (props.variant === 'pricing' && InputPricing) || Input}

  .input__readOnly {
    position: absolute;
    right: 12px;
    bottom: 11px;
  }
`;

/**
 * Div que posiciona o loading
 */
export const SvgContainer = styled.div`
  position: absolute;
  right: 12px;
  bottom: 11px;
`;
