import React, { memo, useMemo, useState, useCallback, useRef } from 'react'

import styled from 'styled-components'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import 'react-datepicker/dist/react-datepicker.css'

import Calendar from '@interco/icons/bidis/v2/action/calendar'
import { useOutsideClick } from '@interco/lib-util'
import { DEFAULT_CLICK_HANDLER } from '@/common/constants'
import { dateToObject } from '@/utils/date'

import { Input, COMMON_MASKS } from '../Input'
import Header from './header'
import * as DatePickerUtils from './utils'
import * as S from './styles'
import { DatePickerProps, DatePickerCustomFormProps, DateFormat } from './types'

export type {
  DatePickerProps,
  DatePickerCustomFormProps,
  DateChange,
  DatePickerHeader,
  DatePickerSchedulesAndEvents,
} from './types'

registerLocale('pt-BR', ptBR)

const Icon = styled(Calendar)`
  fill: var(--primary500);
`

const CustomInput = React.forwardRef(
  (
    {
      value: inputValue,
      canWrite,
      isRangeDate,
      dateFormat,
      hasIcon,
      ...inputAttrs
    }: DatePickerCustomFormProps,
    inputRef?:
      | ((instance: HTMLInputElement | null) => void)
      | React.RefObject<HTMLInputElement>
      | null,
  ) => {
    const renderMaskBasedDateFomart = (formatDate: DateFormat) => {
      if (isRangeDate) return undefined

      if (formatDate === 'dd/MM/yyyy') return COMMON_MASKS.BR_DATE
      if (formatDate === 'MM/yyyy') return COMMON_MASKS.BR_DATE_MONTH_YEAR
      if (formatDate === 'dd/MM') return COMMON_MASKS.BR_DATE_DAY_MONTH

      return undefined
    }

    return (
      <Input
        {...inputAttrs}
        readOnly={!canWrite}
        mask={renderMaskBasedDateFomart(dateFormat)}
        id="datepicker-input"
        value={inputValue}
        ref={inputRef}
        style={{
          width: '100%',
        }}
        type="text"
        iconRight={
          hasIcon && (
            <div data-testid="agenda-icon">
              <Icon height={24} width={24} />
            </div>
          )
        }
      />
    )
  },
)

const Component = React.forwardRef(
  (
    {
      name,
      value,
      startDate: start,
      endDate: end,
      disabled = false,
      onChange,
      onStartDateChange,
      onEndDateChange,
      className,
      isRangeDate: rangeEnabled,
      minDate: min,
      maxDate: max,
      placeholder,
      label,
      error,
      success,
      dataTestId,
      inputEnabled = false,
      id,
      infoText,
      hasIcon,
      inline = false,
      helper,
      dateFormat = 'dd/MM/yyyy',
      schedulesAndEvents,
      carousel = true,
      autoComplete = 'off',
      onFocus,
      ...propsDatePicker
    }: DatePickerProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    const attrs = { error, success, dataTestId, id, infoText, helper, onFocus }
    const [opened, setOpened] = useState<boolean>(false)

    const containerReference = useRef<HTMLDivElement>()
    const startDateReference = useRef<ReactDatePicker>()
    const endDateReference = useRef<ReactDatePicker>()
    const endDate = useMemo(() => (typeof end === 'number' ? new Date(end) : end), [end])

    const isRangeDate = useMemo(() => Boolean(endDate || rangeEnabled), [endDate, rangeEnabled])

    const startDate = useMemo(() => {
      const dateReference = rangeEnabled || isRangeDate ? start : value

      if (typeof dateReference === 'number') {
        return new Date(dateReference)
      }

      return dateReference
    }, [rangeEnabled, isRangeDate, start, value])

    const minDate = useMemo(() => (typeof min === 'number' ? new Date(min) : min), [min])

    const maxDate = useMemo(() => (typeof max === 'number' ? new Date(max) : max), [max])

    const mutualProps = useMemo(() => (isRangeDate ? { open: opened } : {}), [isRangeDate, opened])

    const canWrite = useMemo(() => {
      if (!inputEnabled || isRangeDate || !['dd/MM/yyyy', 'MM/yyyy', 'dd/MM'].includes(dateFormat))
        return false
      return true
    }, [dateFormat, isRangeDate, inputEnabled])

    const renderDayContents = useCallback(
      (dayOfMonth: number, date?: Date) => {
        if (schedulesAndEvents && date) {
          const fullDate = new Intl.DateTimeFormat('fr-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(date)

          const hasSchedulesAndEvents: string[] | undefined = schedulesAndEvents[fullDate]

          if (hasSchedulesAndEvents) {
            return (
              <S.DayContainer>
                <span>{dayOfMonth}</span>

                <S.ScheduleOrEventContainer>
                  {hasSchedulesAndEvents.map((color) => (
                    <React.Fragment key={`${fullDate}-${color}`}>
                      <S.ScheduleOrEvent color={color} />
                    </React.Fragment>
                  ))}
                </S.ScheduleOrEventContainer>
              </S.DayContainer>
            )
          }
        }

        return (
          <S.DayContainer>
            <span>{dayOfMonth}</span>
          </S.DayContainer>
        )
      },
      [schedulesAndEvents],
    )

    const handleDateToString = useCallback((date?: Date | null): string => {
      if (!date) return ''
      return dateToObject(date).formatted
    }, [])

    const closePopUp = useCallback(() => {
      startDateReference?.current?.setOpen?.(false)
      endDateReference?.current?.setOpen?.(false)
      setOpened(false)
    }, [])

    useOutsideClick(containerReference, closePopUp)

    const onCalendarOpen = useCallback(() => {
      if (!isRangeDate) return

      setOpened(true)
      startDateReference?.current?.setOpen?.(true)
      endDateReference?.current?.setOpen?.(true)
    }, [isRangeDate])

    return (
      <S.Container
        ref={(reference) => {
          if (!reference) return
          containerReference.current = reference
        }}
        inline={inline}
      >
        <input
          style={{ display: 'none' }}
          value={
            isRangeDate
              ? JSON.stringify({
                  startDate: handleDateToString(startDate),
                  endDate: handleDateToString(endDate),
                })
              : handleDateToString(startDate)
          }
          onChange={DEFAULT_CLICK_HANDLER}
        />

        <S.DatePicker
          {...propsDatePicker}
          showPopperArrow={false}
          disabledKeyboardNavigation
          fixedHeight
          selectsStart={isRangeDate}
          selectsRange={isRangeDate}
          name={endDate ? `${name}-start` : name}
          selected={startDate}
          disabled={disabled}
          onChange={(date) => {
            if (isRangeDate && date) {
              onStartDateChange?.((date as [Date, Date])[0])
              onEndDateChange?.((date as [Date, Date])[1])
            }
            !isRangeDate && onChange?.(date as Date)

            !isRangeDate && startDateReference.current?.setOpen(false)
          }}
          autoComplete={autoComplete}
          locale="pt-BR"
          dateFormat={dateFormat}
          shouldCloseOnSelect={!isRangeDate}
          wrapperClassName={className}
          renderCustomHeader={(props) => (
            <Header
              {...props}
              inline={inline}
              minYear={minDate?.getFullYear()}
              maxYear={maxDate?.getFullYear()}
              carousel={carousel}
            />
          )}
          renderDayContents={renderDayContents}
          formatWeekDay={DatePickerUtils.formatWeekDay}
          onCalendarOpen={onCalendarOpen}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          placeholderText={placeholder}
          inline={inline}
          {...mutualProps}
          customInput={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <CustomInput
              {...attrs}
              isRangeDate={isRangeDate}
              dateFormat={dateFormat}
              hasIcon={hasIcon}
              canWrite={canWrite}
              label={label}
              infoText={attrs.infoText}
              ref={ref}
            />
          }
          customInputRef={ref as unknown as string}
        />
      </S.Container>
    )
  },
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-datepicker-since-v1-0-8--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3470&t=guTCs4j8yk1eMfXl-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { DatePicker } from '@interco/inter-ui/components/DatePicker'
 *
 * export const MyPage = () => {
 *   const [value, setValue] = useState<Date>()
 *
 *   return (
 *     <DatePicker
 *       value={value}
 *       onChange={(date) => setValue(date)}
 *     />
 *   )
 * }
 * ```
 */
export const DatePicker = memo(Component)
