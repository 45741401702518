import styled, { css } from 'styled-components';
import { Orientation } from '../../interfaces/inter-ui-radio-props';

/**
 * Estilo para o radio default.
 */
const Default = css<{
  margin?: string;
  padding?: string;
  border?: string;
  radioPosition?: Orientation;
}>`
  ${(props) => props.margin && `margin: ${props.margin};`}

  padding: ${(props) => props.padding || `10px 0`};

  ${(props) => props.radioPosition === 'left' && `flex-direction: row-reverse;`}

  ${(props) =>
    props.border !== 'none' &&
    `border-bottom: 1px solid ${props.theme.colors.grayscale.A200}`}
`;

/**
 * Estilo para o radio choose item.
 */
// eslint-disable-next-line prettier/prettier
const ChooseItem = css<{
  margin?: string;
  radioPosition?: Orientation;
  checked?: boolean;
  disabled?: boolean;
}>`
  ${(props) =>
    props.radioPosition !== 'right' && `flex-direction: row-reverse;`}

  margin: ${(props) => props.margin || `12px 0`};
  padding: 16px 12px;
  border: 1px solid ${(props) => props.theme.colors.grayscale.A200};
  border-radius: 8px;

  ${(props) =>
    props.checked &&
    !props.disabled &&
    `border-color: ${props.theme.colors.primary.A300};`}

  ${(props) =>
    props.disabled && `border-color: ${props.theme.colors.grayscale.A100};`}
`;

/**
 * Div principal.
 */
// eslint-disable-next-line prettier/prettier
export const Container = styled.label<{
  margin?: string;
  padding?: string;
  variant?: string;
  radioPosition?: Orientation;
  border?: string;
  checked?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: ${(props) =>
    props.variant === 'radio-top' ? 'flex-start' : 'center'};
  justify-content: space-between;

  ${(props) => (props.variant === 'choose-item' ? ChooseItem : Default)};
`;

/**
 * Div que receberá o InputRadioHidden e o RadioCircle.
 */
export const RadioContent = styled.div<{
  variant?: string;
  radioPosition?: Orientation;
}>`
  position: relative;
  width: 20px;
  height: 20px;
  padding: 6px;
  box-sizing: unset;

  ${(props) =>
    props.variant === 'choose-item' &&
    props.radioPosition !== 'right' &&
    `margin-right: 12px;`}

  ${(props) =>
    props.variant === 'choose-item' &&
    props.radioPosition === 'right' &&
    `margin-left: 12px;`}

    ${(props) =>
    props.variant !== 'choose-item' &&
    props.radioPosition === 'left' &&
    `padding-right: 12px;`}
`;

/**
 * Input radio.
 */
export const InputRadioHidden = styled.input.attrs({ type: 'radio' })`
  display: none;

  &:checked:not(:disabled) {
    + span {
      border-color: ${(props) => props.theme.colors.primary.A500};

      :after {
        opacity: 1;
      }
    }
  }

  &:disabled {
    + span {
      border-color: ${(props) => props.theme.colors.grayscale.A200};
    }

    &:checked {
      + span:after {
        opacity: 1;
        background-color: ${(props) => props.theme.colors.grayscale.A200};
      }
    }
  }
`;

/**
 * Span com formato de radio.
 */
export const RadioCircle = styled.span`
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid ${(props) => props.theme.colors.grayscale.A300};
  border-radius: 50%;
  transition: all 0.5s;
  box-sizing: unset;

  :after {
    content: '';
    position: absolute;
    display: block;
    width: 11px;
    height: 11px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.colors.primary.A500};
    border-radius: 50%;
    opacity: 0;
    transition: all 0.5s;
  }
`;

/**
 * Label que receberá o children.
 */
// eslint-disable-next-line prettier/prettier
export const LabelContent = styled.div<{
  margin?: string;
  variant?: string;
  disabled?: boolean;
}>`
  width: 100%;

  ${(props) => props.variant !== 'choose-item' && `margin-right: 21px;`}

  ${(props) =>
    props.disabled && `* { color: ${props.theme.colors.grayscale.A300} }`}
`;
