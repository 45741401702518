import { css } from 'styled-components';

import { InterIconItems } from './items';

/**
 * Estilos padrões referentes aos ícones.
 */
export const InterIcons = css`
  [class^='inter-ic-'],
  [class*=' inter-ic-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'InterFontIcons' !important;
    /* speak: never; */
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ${InterIconItems}
`;
