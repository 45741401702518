import React from 'react';

import { IInterUIProgressBarProps } from '../../interfaces/inter-ui-progress-bar-props';
import { Bar, Progress } from './InterUIProgressBar.styles';

/**
 * Componente Inter UI Progress Bar.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIProgressBar: React.FC<
  IInterUIProgressBarProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({ progress, rounded, progressColor, ...props }) => {
  return (
    <Bar data-testid='inter-ui-bar' rounded={rounded} {...props}>
      <Progress
        role='progressbar'
        progress={progress}
        rounded={rounded}
        progressColor={progressColor}
      />
    </Bar>
  );
};
