import React, { useState, useRef, useCallback, useMemo } from 'react'

import { useOutsideClick } from '@interco/lib-util'

import { Text } from '../Text'
import * as S from './styles'
import { DatePickerHeader } from './types'

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

type YearProps = {
  year: number
  changeYear: (year: number) => void
  minYear?: number | null
  maxYear?: number | null
}

const Year = ({ year, minYear, maxYear, changeYear }: YearProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  const selectContainer = useRef<HTMLDivElement>(null)
  useOutsideClick(selectContainer, () => visible && setVisible(false))

  const currentYear = new Date().getFullYear()
  const rangeStart = minYear || currentYear - 10
  const rangeEnd = maxYear || currentYear + 10

  const yearOptions = useMemo(() => {
    const options = []
    for (let i = rangeEnd; i >= rangeStart; i -= 1) {
      options.push(
        <S.YearOption
          key={i}
          data-testid={`year-option-${i}`}
          onClick={() => {
            changeYear(i)
            setVisible(false)
          }}
        >
          {i}
        </S.YearOption>,
      )
    }
    return options
  }, [rangeStart, rangeEnd, changeYear])

  const openSelectBox = useCallback(() => {
    yearOptions.length && setVisible(true)
  }, [yearOptions])

  return (
    <S.YearContainer ref={selectContainer}>
      <S.YearSelectContainer visible={visible} optionsLength={yearOptions.length}>
        <S.YearSelect>{yearOptions}</S.YearSelect>
      </S.YearSelectContainer>

      <Text variant="body-3" bold color="typography" colorWeight={500}>
        {year}
      </Text>

      <S.ChevronMore data-testid="arrow-down" onClick={openSelectBox} />
    </S.YearContainer>
  )
}

Year.defaultProps = {
  minYear: null,
  maxYear: null,
}

const Header = ({
  date,
  decreaseMonth,
  increaseMonth,
  changeYear,
  minYear,
  maxYear,
  inline,
  carousel,
}: DatePickerHeader) => {
  const currentMonth = months[date.getMonth()]

  return (
    <S.Header data-testid="datepicker-header" inline={inline}>
      {carousel && <S.ChevronLeft onClick={decreaseMonth} color="var(--primary500)" />}

      <S.MonthAndYearContainer>
        <Text variant="body-3" bold color="typography" colorWeight={500}>
          {currentMonth}
        </Text>

        <Year
          changeYear={changeYear}
          year={date.getFullYear()}
          minYear={minYear}
          maxYear={maxYear}
        />
      </S.MonthAndYearContainer>

      {carousel && <S.ChevronRight onClick={increaseMonth} color="var(--primary500)" />}
    </S.Header>
  )
}

Header.defaultProps = {
  minYear: null,
  maxYear: null,
}

export default Header
