import styled, { css } from 'styled-components'
import ReactDatePicker from 'react-datepicker'

import ChevronLeftIcon from '@interco/icons/orangeds/XL/chevron-left'
import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'
import ChevronMoreIcon from '@interco/icons/orangeds/XL/chevron-down'

type HeaderProps = {
  inline?: boolean
}

export const Header = styled.div<HeaderProps>`
  ${({ inline }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-bottom: 5px;

    ${inline &&
    css`
      justify-content: space-between;
    `}
  `}
`

const iconSize = { width: 24, height: 24 }
export const ChevronLeft = styled(ChevronLeftIcon).attrs(iconSize)`
  cursor: pointer;
  path {
    stroke: var(--primary500);
    stroke-width: 6px;
  }
`

export const ChevronRight = styled(ChevronRightIcon).attrs(iconSize)`
  cursor: pointer;
  path {
    stroke: var(--primary500);
    stroke-width: 6px;
  }
`

export const ChevronMore = styled(ChevronMoreIcon).attrs(iconSize)`
  cursor: pointer;
  height: 20px;
  width: 20px;

  path {
    stroke: var(--primary500);
    stroke-width: 6px;
  }
`

export const YearContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

export const MonthAndYearContainer = styled(YearContainer)`
  & > span:first-child {
    margin-right: 5px;
  }
`

export const YearSelectContainer = styled.div<{
  optionsLength: number
  visible: boolean
}>`
  ${({ optionsLength, visible }) => css`
    position: absolute;
    top: 0;
    width: 100px;
    height: ${optionsLength > 11 ? '297px' : `${optionsLength * 27}px`};
    background: var(--neutral-theme);
    z-index: var(--zIndexMaximum);
    display: ${visible ? 'block' : 'none'};
  `}
`

export const YearSelect = styled.div`
  position: relative;
  display: flex-box;
  flex-flow: column wrap;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: var(--datepicker-year-select-background-color);
  border-radius: var(--datepicker-year-select-border-radius);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
`

export const YearOption = styled.span`
  width: 100%;
  font-size: 0.8rem;
  color: var(--datepicker-year-select-text-color);
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DayContainer = styled.div`
  position: relative;
`

export const ScheduleOrEventContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const ScheduleOrEvent = styled.div<{ color: string }>`
  width: 4px;
  height: 4px;
  background-color: ${(props) => props.color};
  border-radius: 50%;

  & + & {
    margin-left: 2px;
  }
`

type Container = {
  inline: boolean
}

export const Container = styled.div<Container>`
  ${({ inline }) => css`
    display: flex;
    position: relative;

  .react-datepicker__aria-live{
    display: none;
  }

    .react-datepicker {

      min-width: 250px;
      z-index: var(--datepicker-zIndex);
      background-color: var(--datepicker-background-color);
      border-radius: var(--datepicker-v2-border-radius);
      border: 1px solid var(--datepicker-border-color);
      display: flex;
      flex-flow: column wrap;
      padding: 24px;
      font-size: 12px;
      font-weight: 700;

      &__header {
        background-color: transparent;
        border: none;
        padding: 0;
      }

      &__children-container {
        display: none;
      }

      &__day-name {
        color: var(--datepicker-v2-day-names-text-color);
      }

      &__day-names {
        padding: 0;

        &__day-name {
          text-transform: capitalize;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: var(--datepicker-day-names-font-size);
          line-height: 18px;
          color: var(--datepicker-v2-day-names-text-color);
        }

        > div:first-child,
        > div:nth-child(7) {
          color: var(--datepicker-v2-weekend-text-color);
        }
      }

      .react-datepicker__month {
        margin: 0;
      }

      .react-datepicker__month--selected {
        background-color: var(--primary500) !important;
        color: var(--gray500);
      }

      &__month {
        .react-datepicker__week {
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;

          .react-datepicker__day {
            z-index: 0;
            color: var(--datepicker-v2-day-text-color);

            &:hover {
              background-color: transparent;
            }

            &--keyboard-selected {
              color: var(--datepicker-v2-current-day-text-color);
              background-color: transparent;
            }

            &--selected {
              position: relative;
              background-color: transparent;
              border-radius: 0;
              color: var(--datepicker-v2-selected-day-text-color) !important;

              &:after {
                position: absolute;
                content: ' ' !important;
                height: 24px;
                width: 24px;
                border-radius: 80% !important;
                z-index: -1;
                left: 1.5px;
                top: 2px;
                background: var(--datepicker-v2-selected-range-background-color) !important;
              }
            }

            &--today {
              position: relative;
              color: var(--datepicker-v2-current-day-text-color);
              font-weight: 700;

              &:before {
                position: absolute;
                content: ' ';
                height: 24px;
                width: 24px;
                border-radius: 50%;
                z-index: -1;
                left: 1.5px;
                top: 2px;
                background: var(--datepicker-current-day-circle-background-color);
              }
            }

            &.react-datepicker__day--disabled{
              &.react-datepicker__day--today:before {
                color: var(--datepicker-disabled-text-color) !important;
                background-color: transparent;
              }
            }

            &.react-datepicker__day--in-selecting-range {
              background-color: transparent !important;
              position: relative;
              color: var(--gray500)

              &:before {
                position: absolute;
                content: ' ';
                height: 28px;
                width: 33px;
                z-index: -1;
                left: -3px;
                top: 0;
                background-color: var(--datepicker-v2-range-in-selection-background-color);
              }
            }

            &--in-range {
              position: relative;
              border-radius: 0;
              background-color: transparent;
              color: var(--datepicker-range-in-range-text-color) !important;

              &:before {
                position: absolute;
                content: ' ';
                height: 28px;
                border-radius: 0;
                width: 33px;
                z-index: -1;
                left: -3px;
                top: 0;
                background-color: var(--datepicker-v2-range-in-selection-background-color);
              }

              &.react-datepicker__day--outside-month {

                &:before {
                  opacity: 0;
                }
              }

            }

            &--range-end {
              color: var(--datepicker-v2-selected-day-text-color);
              &:before {
                left: -6px;
                background-color: var(--datepicker-v2-range-in-selection-background-color);
                border-radius: 0 var(--radiiMd) var(--radiiMd) 0;
              }

              &:after {
                position: absolute;
                content: ' ' !important;
                height: 24px;
                width: 24px;
                border-radius: 80% !important;
                z-index: -1;
                left: 1.5px;
                top: 2px;
                background: var(--datepicker-v2-selected-range-background-color) !important;
              }
            }

            &--range-start {
              &:before {
                right: -6px;
                background-color: var(--datepicker-v2-range-in-selection-background-color);
                border-radius: var(--radiiMd) 0 0 var(--radiiMd);
                top: 0;
                left: 0;
              }
            }

            &--weekend {
              color: var(--datepicker-v2-weekend-text-color);
            }
            &--disabled {
              color: var(--datepicker-disabled-text-color);
              &:before {
                color: var(--datepicker-disabled-text-color);
              }
            }

            &--outside-month {
              pointer-events: none;
              margin: 0;
              width: 33.5px;
              border-radius: 0;
              color: var(--gray200) !important;



              &:before,
              &:after {
                background-color: transparent !important;
                background: transparent !important;
                color: transparent !important;
              }
            }
          }
        }

        > div:last-child {
          border: none;
          color: var(--gray300);
        }
      }
      .react-datepicker__month-text--keyboard-selected {
        background-color: var(--primary500);
      }
    }

    ${
      inline &&
      css`
    && {
      width: 100%;

      > div {
        width: 100%;
      }

      .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
      }

      .react-datepicker__week {
        justify-content: space-between;
      }
    `
    }
    }
  `}
`

export const DatePicker = styled(ReactDatePicker)`
  ${({ isRightSide }: { isRightSide?: boolean }) => css`
    margin-left: ${isRightSide ? '16px' : '0'};
  `}
`
