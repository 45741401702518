import styled from 'styled-components';

/**
 * Div principal.
 */
export const Container = styled.div<{
  margin?: string;
  borderBottom?: boolean;
  alignItems?: string;
  withBorder?: boolean;
  borderColor?: string;
}>`
  display: flex;

  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}

  ${(props) =>
    props.borderBottom &&
    `padding-bottom: 16px; border-bottom: 1px solid ${props.theme.colors.grayscale.A200}`}

  ${(props) =>
    props.withBorder &&
    `padding: 16px; align-items: center; border-radius: 8px; border: 1px solid ${
      props.borderColor || props.theme.colors.grayscale.A200
    } `}
`;

/**
 * Ícones exibidos nas laterais.
 */
export const Icon = styled.div<{
  left?: boolean;
  right?: boolean;
  align?: string;
}>`
  padding-top: 3px;

  ${(props) => props.left && `margin-right: 16px;`}
  ${(props) => props.right && `margin-left: 16px;`}
  ${(props) => props.align === 'center' && `padding: 0;`}
  ${(props) => props.align && `align-self: ${props.align};`}
`;

/**
 * Conteúdo central.
 */
export const Content = styled.div`
  width: 100%;
`;
