import React from 'react';
import { useTheme } from 'styled-components';

import { IInterUIInputGroupProps } from '../../interfaces/inter-ui-input-group-props';
import { Container, SvgContainer } from './InterUIInputGroup.styles';
import { InterUIIcon } from '../InterUIIcon/InterUIIcon';
import { InterUILoading } from '../../components/InterUILoading/InterUILoading';

/**
 * Componente Inter UI Input Group, design utilizado em input e textarea.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIInputGroup: React.FC<
  IInterUIInputGroupProps & React.HtmlHTMLAttributes<HTMLDivElement>
> = ({ children, loading, ...props }) => {
  const theme = useTheme();

  return (
    <Container data-testid='inter-ui-input-group' {...props}>
      {children}

      {loading ? (
        <SvgContainer role='status'>
          <InterUILoading />
        </SvgContainer>
      ) : null}

      {props.readOnly ? (
        <InterUIIcon
          name='safe-locked'
          size='md'
          color={theme.colors.grayscale.A300}
          className='input__readOnly'
        />
      ) : null}
    </Container>
  );
};
