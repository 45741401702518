import React from 'react'

import { Button } from '../../Button'
import * as S from '../BaseModal/styles'
import { BaseModal, BaseModalProps } from '../BaseModal'

export type AlertModalProps = Omit<BaseModalProps, 'footer'> & {
  onPrimary: () => void
  primaryButtonLabel?: string
  primaryButtonDisabled?: boolean
  primaryActionProcessing?: boolean
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/modals-alert-modal-since-v2-0-20--alert)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/FS12HnaqvY6B4K9mkTCSrr/DS-%2F-Components-%2F-Desktop?node-id=2539-7198&t=RabwOuj1svlNaWmz-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState, useCallback } from 'react'
 *
 * import { AlertModal } from '@interco/inter-ui/components/Modal/AlertModal'
 * import { Button } from '@interco/inter-ui/components/Button'
 *
 * export const MyPage = () => {
 *   const [visible, setVisible] = useState<boolean>(false)
 *
 *   const onModalConfirm = useCallback(() => {
 *     setVisible(false)
 *     // Other actions
 *   }, [])
 *
 *   return (
 *     <>
 *       <Button onClick={() => setVisible(true)}>Open Modal</Button>
 *
 *       <AlertModal
 *         visible={visible}
 *         onClose={() => setVisible(false)}
 *         onPrimary={onModalConfirm}
 *       >
 *         Alert Modal
 *       </AlertModal>
 *     </>
 *   )
 * }
 * ```
 */
export const AlertModal = ({
  onPrimary,
  primaryButtonLabel = 'Entendi',
  primaryButtonDisabled = false,
  primaryActionProcessing = false,
  children,
  ...baseModalProps
}: AlertModalProps) => {
  const footer = (
    <S.ButtonsFooter>
      <Button
        name="primary"
        variant="primary"
        isLoading={primaryActionProcessing}
        disabled={primaryButtonDisabled || primaryActionProcessing}
        onClick={onPrimary}
      >
        {primaryButtonLabel}
      </Button>
    </S.ButtonsFooter>
  )

  return (
    <BaseModal {...baseModalProps} footer={footer}>
      {children}
    </BaseModal>
  )
}
