import React from 'react';

import { IInterUITagProps } from '../../interfaces/inter-ui-tag-props';
import {
  ActionButton,
  ActionCheck,
  Alert,
  Big,
  Counter,
  Default,
  Error,
  InputHidden,
  InputLabel,
  Primary,
  Success,
  Grape,
  Mirtilo,
  Raspberry,
  Avocado,
  Lime,
  Pitaya,
  Strawberry,
  Toranja,
  Coconut,
  Pear
} from './InterUITag.styles';
import { InterUIIcon } from '../../core';

type TagProps = IInterUITagProps &
  React.HtmlHTMLAttributes<HTMLSpanElement> &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

/**
 * Componente Inter UI Tag.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUITag = React.forwardRef<
  HTMLSpanElement & HTMLButtonElement & HTMLInputElement,
  TagProps
>(({ type, variant, margin, counter, children, ref, ...props }, register) => {
  const propsDefault = { ...props, margin, role: 'status' };

  const renderTag = () => {
    switch (variant) {
      case 'primary':
        return <Primary {...propsDefault}>{children}</Primary>;
      case 'alert':
        return <Alert {...propsDefault}>{children}</Alert>;
      case 'success':
        return <Success {...propsDefault}>{children}</Success>;
      case 'error':
        return <Error {...propsDefault}>{children}</Error>;
      case 'grape':
        return <Grape {...propsDefault}>{children}</Grape>;
      case 'mirtilo':
        return <Mirtilo {...propsDefault}>{children}</Mirtilo>;
      case 'raspberry':
        return <Raspberry {...propsDefault}>{children}</Raspberry>;
      case 'avocado':
        return <Avocado {...propsDefault}>{children}</Avocado>;
      case 'lime':
        return <Lime {...propsDefault}>{children}</Lime>;
      case 'pitaya':
        return <Pitaya {...propsDefault}>{children}</Pitaya>;
      case 'strawberry':
        return <Strawberry {...propsDefault}>{children}</Strawberry>;
      case 'toranja':
        return <Toranja {...propsDefault}>{children}</Toranja>;
      case 'coconut':
        return <Coconut {...propsDefault}>{children}</Coconut>;
      case 'pear':
        return <Pear {...propsDefault}>{children}</Pear>;
      case 'big':
        return <Big {...propsDefault}>{children}</Big>;
      case 'action-check':
        return (
          <ActionCheck margin={margin}>
            <InputHidden ref={register} type={type} {...props} />

            <InputLabel htmlFor={props.id}>{children}</InputLabel>
          </ActionCheck>
        );
      case 'action-button':
        return (
          <ActionButton type='button' margin={margin} {...props}>
            {children}

            {(counter || counter === 0) && <Counter>{counter}</Counter>}
          </ActionButton>
        );
      case 'action-dropdown':
        return (
          <ActionButton type='button' margin={margin} {...props}>
            {children}

            <InterUIIcon name='arrow-chevron-down' size='sm' />
          </ActionButton>
        );
      default:
        return <Default {...propsDefault}>{children}</Default>;
    }
  };

  return renderTag();
});
