import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { InterUIBottomSheet } from '../InterUIBottomSheet/InterUIBottomSheet';
import { InterUIIcon } from '../../core/InterUIIcon/InterUIIcon';
import { InterUIInputGroup } from '../../core/InterUIInputGroup/InterUIInputGroup';
import {
  IInterUIDropdownOption as DropdownOption,
  IInterUIDropdownProps
} from '../../interfaces/inter-ui-dropdown-props';
import { RemoveAccents } from '../../utils/inter-ui-providers';
import {
  Container,
  ContentOptions,
  DropdownFake,
  Label,
  Option,
  Button,
  Loading
} from './InterUIDropdown.styles';
import { InterUILoading } from '../InterUILoading/InterUILoading';

/**
 * Componente Inter UI Dropdown.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIDropdown: React.FC<IInterUIDropdownProps> = ({
  margin,
  contentHeight,
  name,
  label,
  labelOptions = 'Selecione uma opção',
  placeholder,
  value = null,
  options,
  disabled = false,
  onChange,
  hideSearch = false,
  showCloseLink = false,
  onFilter,
  helperButton,
  onClickHelper,
  loading,
  focusSearch,
  truncateOption = true,
  ...props
}) => {
  const theme = useTheme();
  const searchRef = useRef<HTMLInputElement>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [selected, setSelected] = useState<DropdownOption>();
  const [filteredData, setFilteredData] = useState<DropdownOption[]>([]);
  const [emptySearch, setEmptySearch] = useState<boolean>(true);

  let debounce: NodeJS.Timeout;

  useEffect(() => {
    setSelected(undefined);
    setFilteredData(options);

    setValue();
  }, [options]);

  useEffect(() => {
    setValue();
  }, [value]);

  useEffect(() => {
    if (focusSearch && toggle && searchRef.current) {
      searchRef.current.focus();
    }
  }, [toggle, focusSearch]);

  const setValue = () => {
    options.map((item) => (item.value === value ? setSelected(item) : null));
  };

  /**
   * Apaga valor do input de search e limpa os dados do filtro.
   */
  const deleteSearchValue = () => {
    setEmptySearch(true);
    setFilteredData(options);

    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  /**
   * Exibir o bottom sheet com as opções somente se o dropdown não estiver desabilitado.
   */
  const showOptions = () => setToggle(!disabled);

  /**
   * Ocultar o bottom sheet com as opções.
   */
  const hideOptions = () => {
    setToggle(false);

    setTimeout(() => {
      deleteSearchValue();
    }, 500);
  };

  /**
   * Evento disparado ao clicar em uma das opções exibidas no bottom sheet.
   * @param item Item selecionado.
   */
  const changeOption = (item: DropdownOption) => {
    setSelected(item);

    onChange(item.value);

    hideOptions();
  };

  /**
   * Filtro default para o input search.
   * @param search Dado informado no input.
   * @param item Opção do dropdown para realizar a comparação.
   */
  const onFilterDefault = (search: string, item: DropdownOption) => {
    return RemoveAccents(item.label).includes(search);
  };

  /**
   * Evento disparado ao digitar informações no search para filtrar.
   * @param event KeyboardEvent disparado.
   */
  const keyUpSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;

    const search = RemoveAccents(value);
    const filter = onFilter || onFilterDefault;

    setEmptySearch(!value);

    clearTimeout(debounce);

    debounce = setTimeout(() => {
      setFilteredData(
        options.filter((item, index) => filter(search, item, index))
      );
    }, 300);
  };

  /**
   * Renderização da lista de opções para o bottom sheet.
   */
  const renderOptions = filteredData.map((item, index) => (
    <Option
      key={`dropdown-${index}`}
      role='option'
      truncateOption={truncateOption}
      onClick={() => changeOption(item)}
    >
      <p>{item.label}</p>

      <InterUIIcon
        name='arrow-chevron-right'
        size='md'
        color={theme.colors.primary.A500}
      />
    </Option>
  ));

  return (
    <Container margin={margin} {...props}>
      <Label
        id={name}
        role='label'
        disabled={disabled}
        useHelperButton={!!helperButton}
      >
        {label}

        {helperButton && (
          <Button type='button' onClick={onClickHelper}>
            {helperButton}
          </Button>
        )}
      </Label>

      <DropdownFake
        onClick={showOptions}
        disabled={disabled}
        placeholder={!selected ? placeholder : undefined}
        role='listbox'
        aria-labelledby={name}
        aria-disabled={disabled}
        aria-haspopup='true'
        aria-expanded={toggle}
      >
        <p>{selected?.label || placeholder}</p>

        {loading ? (
          <Loading>
            <InterUILoading />
          </Loading>
        ) : (
          <InterUIIcon
            name='arrow-chevron-down'
            size='md'
            color={
              disabled ? theme.colors.grayscale.A200 : theme.colors.primary.A500
            }
          />
        )}
      </DropdownFake>

      <InterUIBottomSheet
        title={labelOptions}
        toggle={toggle}
        onHide={hideOptions}
        link={showCloseLink ? 'Fechar' : undefined}
        onClickedLink={hideOptions}
        aria-labelledby={name}
      >
        {!hideSearch && (
          <InterUIInputGroup className='group-search' margin='0 0 20px'>
            {emptySearch && (
              <InterUIIcon
                name='search'
                size='sm'
                color={theme.colors.primary.A500}
              />
            )}

            <input
              ref={searchRef}
              data-testid='search'
              type='search'
              name='dropdown-search'
              placeholder='Pesquisar'
              autoComplete='off'
              onKeyUp={keyUpSearch}
              aria-labelledby={name}
            />

            {!emptySearch && (
              <InterUIIcon
                data-testid='deleteSearchValue'
                onClick={deleteSearchValue}
                name='contextual-error'
                size='16px'
                color={theme.colors.primary.A500}
              />
            )}
          </InterUIInputGroup>
        )}

        <ContentOptions contentHeight={contentHeight} hideSearch={hideSearch}>
          {renderOptions}
        </ContentOptions>
      </InterUIBottomSheet>
    </Container>
  );
};
