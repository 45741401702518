/**
 * Paleta de cores referente ao tema dark do Inter.
 */
export const InterDarkTheme = {
  title: 'dark',
  name: 'Dark',

  colors: {
    brand: {
      orange: '#FF7A00',
      black: '#212121',
      win: '#323747',
      one: '#EFEEED'
    },

    primary: {
      A500: '#FF7A00',
      A400: '#B65C0A',
      A300: '#904C0F',
      A200: '#553417',
      A100: '#2E241C'
    },

    grayscale: {
      A500: '#F0F1F6',
      A400: '#B6B7BB',
      A300: '#616368',
      A200: '#353539',
      A100: '#252628'
    },

    opacity: {
      A500: 'rgba(22,22,22,0.80)',
      A400: 'rgba(22,22,22,0.64)',
      A300: 'rgba(22,22,22,0.48)',
      A200: 'rgba(22,22,22,0.16)',
      A100: 'rgba(22,22,22,0.08)'
    },

    strawberry: {
      A500: '#EF5350',
      A400: '#BC4644',
      A300: '#883838',
      A200: '#572B2C',
      A100: '#332224'
    },

    pitaya: {
      A500: '#EC407A',
      A400: '#C43969',
      A300: '#973154',
      A200: '#67293F',
      A100: '#3B212C'
    },

    grape: {
      A500: '#9575CD',
      A400: '#6C4CA4',
      A300: '#563F7F',
      A200: '#3E3157',
      A100: '#2B2537'
    },

    mirtilo: {
      A500: '#7986CB',
      A400: '#505DA3',
      A300: '#424B7E',
      A200: '#323857',
      A100: '#262836'
    },

    raspberry: {
      A500: '#039BE5',
      A400: '#0A75A9',
      A300: '#10567A',
      A200: '#163A4E',
      A100: '#192A34'
    },

    avocado: {
      A500: '#26A69A',
      A400: '#237A72',
      A300: '#205955',
      A200: '#1F4140',
      A100: '#1E3131'
    },

    lime: {
      A500: '#689F38',
      A400: '#4A6C2E',
      A300: '#3B5229',
      A200: '#2C3823',
      A100: '#242921'
    },

    pear: {
      A500: '#9E9D24',
      A400: '#6F6F22',
      A300: '#4B4A20',
      A200: '#31311F',
      A100: '#29291F'
    },

    toranja: {
      A500: '#EA5527',
      A400: '#B1411E',
      A300: '#88371E',
      A200: '#5D2C1E',
      A100: '#34221E'
    },

    coconut: {
      A500: '#A28176',
      A400: '#664A40',
      A300: '#4B3833',
      A200: '#382D2B',
      A100: '#282323'
    },

    success: {
      A500: '#03A74F',
      A100: '#1A2A23'
    },

    alert: {
      A500: '#FAC405',
      A100: '#302B1C'
    },

    error: {
      A500: '#F5554D',
      A100: '#302122'
    },

    elevation: {
      theme: '#252628',
      A100: '#353539',
      A200: '#616368'
    },

    base: {
      theme: '#1C1C1E',
      typography: '#F0F1F6',
      white: '#FFFFFF',
      black: '#161616'
    }
  }
};
