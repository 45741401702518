import styled from 'styled-components';

export const Container = styled.div<{ backgroundColor?: string }>`
  display: flex;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.grayscale.A100};
  border-radius: 8px;
  overflow: hidden;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 6 1;
  padding: 16px;
`;

export const RightContent = styled.figure<{ fullImg?: boolean }>`
  display: flex;
  flex: 4 1;
  justify-content: ${(props) => (props.fullImg ? `flex-end` : `center`)};
  padding: ${(props) => !props.fullImg && `16px`};

  img {
    align-self: ${(props) => !props.fullImg && `center`};
    height: auto;
  }
`;
