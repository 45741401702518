import { capitalize } from '@interco/lib-util'

export const formatWeekDay = (dayOfWeekLabel: string | Date) => {
  if (typeof dayOfWeekLabel === 'string') {
    return capitalize((dayOfWeekLabel as string).substring(0, 3))
  }

  const dayLabel = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
  return dayLabel[dayOfWeekLabel.getDay()]
}
