/**
 * Paleta de cores referente ao tema do Inter.
 */
export const InterLightTheme = {
  title: 'light',
  name: 'Light',

  colors: {
    brand: {
      orange: '#FF7A00',
      black: '#212121',
      win: '#323747',
      one: '#EFEEED'
    },

    primary: {
      A500: '#FF7A00',
      A400: '#FF9D42',
      A300: '#FFB46E',
      A200: '#FFDAB7',
      A100: '#FFF2E7'
    },

    grayscale: {
      A500: '#161616',
      A400: '#6A6C72',
      A300: '#B6B7BB',
      A200: '#DEDFE4',
      A100: '#F5F6FA'
    },

    opacity: {
      A500: 'rgba(245,246,250,0.80)',
      A400: 'rgba(245,246,250,0.64)',
      A300: 'rgba(245,246,250,0.48)',
      A200: 'rgba(245,246,250,0.16)',
      A100: 'rgba(245,246,250,0.08)'
    },

    strawberry: {
      A500: '#D32F2F',
      A400: '#EF5350',
      A300: '#EF9A9A',
      A200: '#FFCDD2',
      A100: '#FFEBEE'
    },

    pitaya: {
      A500: '#C2185B',
      A400: '#EC407A',
      A300: '#F48FB1',
      A200: '#F8BBD0',
      A100: '#FCE4EC'
    },

    grape: {
      A500: '#512DA8',
      A400: '#7E57C2',
      A300: '#B39DDB',
      A200: '#D1C4E9',
      A100: '#EDE7F6'
    },

    mirtilo: {
      A500: '#303F9F',
      A400: '#5C6BC0',
      A300: '#9FA8DA',
      A200: '#C5CAE9',
      A100: '#E8EAF6'
    },

    raspberry: {
      A500: '#0277BD',
      A400: '#039BE5',
      A300: '#4FC3F7',
      A200: '#B3E5FC',
      A100: '#E1F5FE'
    },

    avocado: {
      A500: '#00796B',
      A400: '#26A69A',
      A300: '#80CBC4',
      A200: '#B2DFDB',
      A100: '#DDF0EF'
    },

    lime: {
      A500: '#33691E',
      A400: '#7CB342',
      A300: '#AED581',
      A200: '#DCEDC8',
      A100: '#F1F8E9'
    },

    pear: {
      A500: '#9E9D24',
      A400: '#C0CA33',
      A300: '#DCE775',
      A200: '#F0F4C3',
      A100: '#F9FBE7'
    },

    toranja: {
      A500: '#D84315',
      A400: '#FF7043',
      A300: '#FFAB91',
      A200: '#FFCCBC',
      A100: '#FBE9E7'
    },

    coconut: {
      A500: '#5D4037',
      A400: '#795548',
      A300: '#A1887F',
      A200: '#D7CCC8',
      A100: '#EFEBE9'
    },

    success: {
      A500: '#00AA4F',
      A100: '#E9F8F0'
    },

    alert: {
      A500: '#FFC700',
      A100: '#FFF7D9'
    },

    error: {
      A500: '#F56A50',
      A100: '#FEF0ED'
    },

    elevation: {
      theme: '#FFFFFF',
      A100: '#F5F6FA',
      A200: '#FFFFFF'
    },

    base: {
      theme: '#FFFFFF',
      typography: '#161616',
      white: '#FFFFFF',
      black: '#161616'
    }
  }
};
