import { createGlobalStyle } from 'styled-components';

import { InterIcons } from './icons';
import { InterResetCSS } from './inter-ui-reset';

/**
 * CSS global com os padrões do Design System Inter.
 */
export const GlobalUIInterStyle = createGlobalStyle`

  ${InterResetCSS}

  ${InterIcons}

  body {
    background-color: ${(props) => props.theme.colors.base.theme};
    font-family: 'Inter', 'Lato', sans-serif;
    color: ${(props) => props.theme.colors.base.typography};
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Sora', 'Lato', sans-serif;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  caption, small {
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: ${(props) => props.theme.colors.grayscale.A400};
  }

  strong {
    font-weight: bold;
  }

`;
