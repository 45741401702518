import styled from 'styled-components'

import { Container as InputContainer, Input } from '../Input/styles'
import type { ContainerProps } from './types'

export const Component = styled.div<ContainerProps>`
  position: relative;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};

  ${InputContainer} {
    position: relative;
    min-width: 240px;
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
    border-radius: var(--input-border-radius);
  }

  ${Input} {
    transition: all ease 0.2s;
    padding: 0 40px;

    &::placeholder {
      color: var(--input-placeholder-text-color);
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      cursor: not-allowed;
      border-radius: var(--radiiMd);
    }
  `}
`

export const Icon = styled.span<ContainerProps>`
  position: absolute;
  top: 12px;
  left: 16px;

  svg {
    transition: all ease 0.3s;

    ${({ isFocused, isDisabled }) =>
      isFocused || isDisabled
        ? `stroke: var(--search-input-icon-disabled-color)`
        : `stroke: var(--search-input-icon-color)`}
  }
`

export const ClearButton = styled.button`
  position: absolute;
  display: flex;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: var(--radiiSm);
  background-color: transparent;
  cursor: pointer;
  transition: all ease 0.3s;

  svg {
    stroke: var(--search-input-clear-button-icon-color);
  }

  &:hover {
    background-color: var(--search-input-clear-button-hover-background-color);
  }
`
