import styled from 'styled-components';

/**
 * Div overlay.
 */
export const Overlay = styled.div<{ toggle: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(22, 22, 22, 0.64);
  z-index: 98;
  visibility: ${(props) => (props.toggle ? 'visible' : 'hidden')};
  transition: all 0.3s;

  ${(props) => !props.toggle && `transition-delay: 0.5s;`}
`;

/**
 * Div principal contendo todo o conteúdo do bottom sheet.
 */
export const Content = styled.div<{
  toggle: boolean;
  backgroundColor?: string;
}>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: calc(100vh - 80px);
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.elevation.theme};
  border-radius: 8px 8px 0 0;
  overflow: auto;
  z-index: 99;
  transform: translate3d(0, 100%, 0);
  transition: all 0.5s;
  transition-duration: 0.5s;
  transition-delay: 0.1s;

  ${(props) => props.toggle && `transform: translate3d(0, 0%, 0);`}
`;

/**
 * Manipulador para fechar o bottom sheet.
 */
export const Handler = styled.div`
  padding: 12px 24px;

  ::after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 48px;
    height: 4px;
    background-color: ${(props) => props.theme.colors.grayscale.A200};
    border-radius: 8px;
  }
`;

/**
 * Header com botão alinhado a direita.
 */
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 24px 20px;

  h1 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

/**
 * Link posicionado a direita.
 */
export const Link = styled.button.attrs({ type: 'button' })`
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: ${(props) => props.theme.colors.primary.A500};
`;

/**
 * Div principal para o children.
 */
export const Wrap = styled.div`
  margin: 0 24px 24px;
  max-height: calc(100vh - 180px);
  overflow: scroll;
`;
